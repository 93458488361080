import React from 'react'
import Layout from '@components/Layout'
import { Link } from 'gatsby'
import { Seo } from '@components/Seo'
import PageTitle from '@components/PageTitle'
import { TitleParagraph } from '@global'

const VisitIndex = () => (
  <Layout>
    <Seo title="Visiting Sowerby Bridge | The Town and Business Guide For Residents and Visitors" />
    <PageTitle title="Visiting Sowerby Bridge" />
    <TitleParagraph>We welcome you to the town and hope you enjoy your visit, please find links to information below that you may find of interest</TitleParagraph>
    <ul>
      <li>
        <Link to="/businesses/accommodation">Places to Stay</Link>
      </li>
      <li>
        <Link to="/businesses/eatingout">Places to Eat</Link>
      </li>
      <li>
        <Link to="/businesses/retail">Where to Shop</Link>
      </li>
      <li>
        <Link to="/businesses/marketstall">Sowerby Bridge Market</Link>
      </li>
      <li>
        <Link to="/visit/parking">Car Parking</Link>
      </li>
      <li>
        <Link to="/visit/howtogethere">How to Get Here</Link>
      </li>
      <li>
        <Link to="/gallery/">Gallery</Link>
      </li>
    </ul>
  </Layout>
)

export default VisitIndex
